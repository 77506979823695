/**
 * Stactize.Portal.BFF
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EmailType = 'ActivationSuccess' | 'ActivationFailed' | 'UpdateSuccess' | 'UpdateFailed' | 'UpdateInProgress' | 'Suspended' | 'Reinstated' | 'DeletedSuccess' | 'ActivationSuccessWithNoLogin' | 'UpdateSuccessWithNoLogin';

export const EmailType = {
    ActivationSuccess: 'ActivationSuccess' as EmailType,
    ActivationFailed: 'ActivationFailed' as EmailType,
    UpdateSuccess: 'UpdateSuccess' as EmailType,
    UpdateFailed: 'UpdateFailed' as EmailType,
    UpdateInProgress: 'UpdateInProgress' as EmailType,
    Suspended: 'Suspended' as EmailType,
    Reinstated: 'Reinstated' as EmailType,
    DeletedSuccess: 'DeletedSuccess' as EmailType,
    ActivationSuccessWithNoLogin: 'ActivationSuccessWithNoLogin' as EmailType,
    UpdateSuccessWithNoLogin: 'UpdateSuccessWithNoLogin' as EmailType
};

