/**
 * Stactize.Portal.BFF
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OrchestrationMethod = 'ServiceBus' | 'Email';

export const OrchestrationMethod = {
    ServiceBus: 'ServiceBus' as OrchestrationMethod,
    Email: 'Email' as OrchestrationMethod
};

